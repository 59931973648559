<template>
    <div>
        <el-table stripe v-loading="loading" :data="dataList" >
            <el-table-column label="申请编号" prop="outOrderNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="活动政策编号" prop="activityNo" min-width="160" :show-overflow-tooltip="true"/>
            <el-table-column label="活动政策名称" prop="activityName" min-width="160" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商" prop="parentAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}} - {{scope.row.agentName}}
                </template>
            </el-table-column>
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="执行结果" prop="executeStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span v-if="scope.row.executeStatus=='SUCCESS'">成功</span>
                    <span v-if="scope.row.executeStatus=='FAILED'">失败</span>
                </template>
            </el-table-column>
            <el-table-column label="调价参数" prop="changeParam" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="调价结果" prop="resultMsg" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="执行时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {MerchantApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                isAdd: false,
                dialogVisible: false,
                agentLevelShow: false,
                agentRankInfo: [],
                orgRankInfo:[]
            }
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await MerchantApi.ConfigApplyJobLog(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            }
        }
    };
</script>

<style scoped>

</style>
